import React from 'react';
import * as Icon from 'react-feather';

const ContactInfo = () => {
    return (
        <div className="contact-info-area ptb-80">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Mail />
                            </div>
                            <h3>Email Here</h3>
                            <p><a href="mailto:info@karigar.pk">info@karigar.pk</a></p>
                            <p><a href="mailto:jobs@karigar.pk">jobs@karigar.pk</a></p>
                            <p><a href="mailto:faisal@karigar.pk">faisal@karigar.pk</a></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.MapPin />
                            </div>
                            <h3>Karigar Web Solutions</h3>
                            <p>Tariq Square opp 1st floor, Kashmir Road, Sialkot, Punjab. Pakistan - 51310</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Phone />
                            </div>
                            <h3>Call Here</h3>
                            <p><a href="tel:+92523573526">+92 52 3573526</a></p>
                            <p><a href="tel:+923173334373">+92 317 3334373</a></p>
                            <p><a href="tel:+13024992052">+1 302 499 2052</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo;  