import React from "react";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import ContactInfo from '../components/Contact/ContactInfo';
import GoogleMap from '../components/Contact/GoogleMap';
import ContactForm from '../components/Contact/ContactForm';
import NavbarStyleSix from "../components/_App/NavbarStyleSix";

const Contact = () => (
    <Layout>
        <Seo title="Contact" />

        <NavbarStyleSix />

        <PageBanner pageTitle="Contact Us" />

        <ContactInfo />

        {/*<GoogleMap />*/}

        <ContactForm />
        
        <Footer /> 
    </Layout>
)

export default Contact;